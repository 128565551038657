<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <Navbar />
        <v-row v-if="success" justify="center" class="mt-10">
            <v-col cols=auto>
                <span class="display-1">{{ $t("mailSubscription.cancelMessage") }}</span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios'
    import Navbar from '@/components/partials/Navbar'
    export default {
        name: "MailSubscription",
        components: {Navbar},
        data() {
            return {
                success: false,
                dialog: false,
            }
        },
        created() {
            axios.post('/auth/mail_subscription', {subscribe: 0})
            .then(() => {
                this.success = true
            })
            .catch(() => {
                this.dialog = true
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>